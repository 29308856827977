module.exports = {
  light: {
    primary: "#189B30",
    secondary: "#F7F7F7",
    accent: "#4d4d4d",
    error: "#FF5252",
    info: "#2196F3",
    success: "#4CAF50",
    warning: "#FFC107"
  },
  headerColor: "primary"
};
